module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-165712241-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Cool Application","short_name":"Cool App","description":"The application does cool things and makes your life better.","display":"standalone","icon":"src/images/favicon-32x32.png","start_url":"/","background_color":"#FFF","theme_color":"#FFF"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
